<template>
  <div>
    <window-header></window-header>
    <section>
      <article style="height: 287px; width: 470px">
        <fieldset style="
            position: absolute;
            left: 10px;
            top: 5px;
            height: 70px;
            width: 448px;
          ">
          <legend>
            <input
              type="radio"
              name="accion"
              style="height: 1em; margin: 0"
              :checked="accion == 1"
              @change="accion = 1"
            />
            Modificar/Eliminar Documento
          </legend>
          <field
            name="plantilla_editar_eliminar"
            label="Seleccione el elemento a modificar o eliminar"
            dbAdapter="plantilla_listado"
            widget="m2o"
            width="360px"
            style="top: 0px; left: 17px"
            :readonly="accion != 1"
          />
          <button
            style="position: absolute; top: 6px; left: 380px"
            v-tooltip="'Eliminar'"
            class="button"
            :class="{ disabled: !formData.plantilla_editar_eliminar }"
            @click="eliminar"
          >
            <fa-icon icon="trash" />
          </button>
        </fieldset>

        <fieldset style="
            position: absolute;
            left: 10px;
            top: 85px;
            height: 70px;
            width: 448px;
          ">
          <legend>
            <input
              type="radio"
              name="accion"
              style="height: 1em; margin: 0"
              :checked="accion == 2"
              @click="accion = 2"
            />
            Crear nuevo documento en blanco
          </legend>
          <field
            name="nombre_nuevo_blanco"
            label="Nombre del nuevo documento"
            width="400px"
            style="top: 0px; left: 17px"
            :readonly="accion != 2"
          />
        </fieldset>

        <fieldset style="
            position: absolute;
            left: 10px;
            top: 165px;
            height: 110px;
            width: 448px;
          ">
          <legend>
            <input
              type="radio"
              name="accion"
              style="height: 1em; margin: 0"
              :checked="accion == 3"
              @click="accion = 3"
            />
            Crear nuevo documento partiendo de uno existente
          </legend>
          <field
            name="plantilla_nuevo"
            label="Documento origen"
            dbAdapter="plantilla_listado"
            widget="m2o"
            width="400px"
            style="top: 0px; left: 17px"
            :readonly="accion != 3"
          />
          <field
            name="nombre_nuevo_plantilla"
            label="Nombre del nuevo documento"
            widget="char"
            width="400px"
            style="top: 35px; left: 17px"
            :readonly="accion != 3"
          />
        </fieldset>
      </article>
    </section>
    <nav>
      <button
        title="Diseñar"
        name="disenar"
        class="button"
        @click="disenar"
        :class="{
          disabled:
            !accion ||
            (accion == 1 && !formData.plantilla_editar_eliminar) ||
            (accion == 2 && !formData.nombre_nuevo_blanco) ||
            (accion == 3 &&
              (!formData.nombre_nuevo_plantilla || !formData.plantilla_nuevo)),
        }"
      >
        <fa-icon icon="paint-brush" /> Diseñar
      </button>
      <button
        title="Cancelar"
        class="button"
        @click="win.instance.$emit('close')"
      >
        <fa-icon icon="times" /> Cancelar
      </button>
    </nav>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  props: {
    model: {
      required: true,
      type: String
    }
  },
  data: function () {
    var self = this;
    return {
      title: "Diseñar documentos",
      dbAdapter: "plantilla_listado",
      primary: "codigo",
      mode: "new",
      defaultData: {
        estado: "pendiente",
        fecha: new Date().yyyymmdd(),
      },
      sequence: {
        name: "reparacion",
      },
      accion: null,
    };
  },
  methods: {
    disenar: function () {
      var self = this;
      switch (self.accion) {
        case 1:
          window.open(
            self.app.urlGeneradorInformesListadoEditor + '?id=' +
            self.formData.plantilla_editar_eliminar.id +
            "&token=" +
            window.session.token,
            "_blank"
          );
          self.$emit("close");
          break;
        case 2:
          window.DB.post("plantilla_listado", {
            data: {
              nombre: self.formData.nombre_nuevo_blanco,
              model: self.model,
            }
          }).then(function (res) {
            window.open(
              self.app.urlGeneradorInformesListadoEditor + '?id=' + res.id + "&token=" + window.session.token,
              "_blank"
            );
            self.$emit("close");
          });
          break;
        case 3:
          window.DB.get("plantilla_listado", {
            itemId: self.formData.plantilla_nuevo.id,
            fields: ["html", "default_size", "page_height", "page_width", "margin_top", "margin_bottom", "margin_right", "margin_left", "json"],
          }).then(function (data) {
            window.DB.post("plantilla_listado", {
              data: {
                nombre: self.formData.nombre_nuevo_plantilla,
                html: data.html,
                model: self.model,
                default_size: data.default_size,
                page_height: data.page_height,
                page_width: data.page_width,
                margin_top: data.margin_top,
                margin_bottom: data.margin_bottom,
                margin_right: data.margin_right,
                margin_left: data.margin_left,
              }
            }).then(function (res) {
              window.open(self.app.urlGeneradorInformesListadoEditor + '?id=' + res.id + "&token=" + window.session.token, "_blank");
              self.$emit("close");
            });
          });
          break;
      }
    },
    eliminar: function () {
      let self = this;
      self.app.confirm('¿Seguro que desea eliminar la plantilla?', function () {
        window.DB.delete("plantilla_listado", { itemId: self.formData.plantilla_editar_eliminar.id })
          .then(function () {
            self.app.toast('Plantilla eliminada', 'success');
            self.clear();
          }).catch(function (e) {
            self.app.toast('No se pudo eliminar la plantilla', 'error');
          })
      })


    }
  },
};
</script>